import { SortDirectionEnum } from '@libs/shared/enums';
import { IDatatableSort } from '@libs/shared/interfaces';

export class DatatableSortingHelper {
  private _prop!: string;
  private _dir!: SortDirectionEnum;
  private _initial: IDatatableSort[] = [];

  public get prop(): string {
    return this._prop;
  }

  public get dir(): SortDirectionEnum {
    return this._dir;
  }

  public get initial(): IDatatableSort[] {
    return this._initial;
  }

  public add(prop: string, dir = SortDirectionEnum.Asc): void {
    this._prop = prop;
    this._dir = dir;

    if (!this._initial.length) {
      this._initial = [{ prop, dir }];
    }
  }
}
