import { ActionReducer } from '@ngrx/store';

export const excludedActionTypes = [
  '@ngrx/effects/init',
  '@ngrx/store/init',
  '@ngrx/store/update-reducers',
  '@ngrx/store-devtools/recompute',
  '@ngrx/effects/update-effects',
];

export const storeLogger = <T = any>(reducer: ActionReducer<T>): ActionReducer<T> => {
  return (state, action) => {
    if (!excludedActionTypes.includes(action.type)) {
      console.log(action);
    }

    return reducer(state, action);
  };
};
