import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@auth0/auth0-angular';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JwtDecoderService {
  private decodedTokenSubject = new ReplaySubject<any>(1);
  private accountRoleSubject = new ReplaySubject<any>(1);
  private accountPermissionsSubject = new ReplaySubject<any>(1);
  public jwtHelper: JwtHelperService = new JwtHelperService();
  public role: string[] = [];
  public permissions: string[] = [];
  public readonly adminRole = 'Scylla_TenantAdmin';
  public readonly roleKey: string = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

  constructor(public authService: AuthService) {
    this.loadAndDecodeToken();
  }

  public get decodedToken$(): any {
    return this.decodedTokenSubject.asObservable();
  }

  public get accountRole$(): any {
    return this.accountRoleSubject.asObservable();
  }

  public get accountPermissions$(): any {
    return this.accountPermissionsSubject.asObservable();
  }

  protected loadAndDecodeToken(): void {
    this.authService.getAccessTokenSilently().subscribe(token => {
      const decodedToken = this.jwtHelper.decodeToken(token);
      if (decodedToken) {
        this.decodedTokenSubject.next(decodedToken);
        this.getRole(decodedToken);
        this.getPermissions(decodedToken);
      }
    });
  }

  protected getRole(token: any): void {
    const role = token[this.roleKey];
    this.role = role;
    this.accountRoleSubject.next(role);
  }

  protected getPermissions(token: any): void {
    const permissions = token.permissions;
    this.permissions = permissions;
    this.accountPermissionsSubject.next(permissions);
  }

  public checkPermission(permission: string): boolean {
    return this.role.includes('Scylla_TenantAdmin') || this.permissions.includes(permission);
  }
}
