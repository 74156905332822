import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpTenantIdInterceptor implements HttpInterceptor {
  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const tenantID = localStorage.getItem('TenantId');

    if (!tenantID) {
      return next.handle(request);
    }

    const modifiedReq = request.clone({
      headers: request.headers.set('TenantId', `${tenantID}`),
    });

    return next.handle(modifiedReq);
  }
}
