import { Injectable } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class ActionStreamService {
  constructor(private actionsSubject$: ActionsSubject) {}

  public get stream$(): ActionsSubject {
    this.actionsSubject$.next({ type: 'Init Action Stream' });

    return this.actionsSubject$;
  }
}
