export enum ButtonRoleEnum {
  Button = 'button',
  Submit = 'submit',
}

export enum ButtonAppearanceEnum {
  Solid = 'solid',
  Outline = 'outline',
}

export enum ButtonThemeEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  Additional = 'additional',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Disabled = 'disabled',
  Dark = 'dark',
  Black = 'black',
  White = 'white',
}

export enum ButtonSizeEnum {
  Big = 'big',
  Medium = 'medium',
  Small = 'small',
}

export enum ButtonContentEnum {
  Text = 'text',
  Icon = 'icon',
  TextAndIcon = 'textandicon',
}
