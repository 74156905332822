import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HTTPHeaderEnum, NotificationTypeEnum } from '../enums';
import { ToastService } from '../services';

@Injectable()
export class HttpToastsInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skip500ErrorHandling = HTTPHeaderEnum.Skip500ErrorHandling;
    const skip500ErrorHandlingHeader = !!req.headers.get(skip500ErrorHandling);

    return next.handle(req).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 500 && !skip500ErrorHandlingHeader) {
            this.toastService.show(
              $localize`An error occurred. Please try again later`,
              NotificationTypeEnum.Error,
              {
                classname: 'bg-danger',
              }
            );

            return throwError(() => new Error(error.message));
          }
        }

        return throwError(() => error);
      })
    );
  }
}
