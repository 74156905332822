export const formatUTCDate = (value: string): string => {
  const date = new Date(value);
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  const paddedMonth = month.toString().padStart(2, '0');
  const paddedDay = day.toString().padStart(2, '0');

  return `${paddedMonth}/${paddedDay}/${year}`;
};
