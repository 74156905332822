import { User } from '@auth0/auth0-angular';

export const AUTH_FEATURE_KEY = 'auth';

export interface IAuthState {
  user: User | null;
}

export interface IAuthFeature {
  readonly [AUTH_FEATURE_KEY]: IAuthState;
}

export const authInitialState: IAuthState = {
  user: null,
};
