import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, take } from 'rxjs';

import { WINDOW } from '@libs/shared/providers';

import {
  checkSessionAction,
  getUserDetailsAction,
  getUserDetailsFailedAction,
  getUserDetailsSucceededAction,
  performLoginAction,
  performLogoutAction,
} from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    @Inject(WINDOW) private window: Window
  ) {}

  public checkSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkSessionAction),
      switchMap(() =>
        this.authService.isAuthenticated$.pipe(
          take(1),
          map(isAuthenticated => {
            if (!isAuthenticated) {
              return performLoginAction();
            }

            return getUserDetailsAction();
          })
        )
      )
    )
  );

  public performLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(performLoginAction),
        switchMap(() => {
          const { href, origin } = this.window.location;

          return this.authService.loginWithRedirect({
            appState: { target: href.replace(origin, '') },
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  public performLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(performLogoutAction),
        switchMap(() =>
          this.authService.logout({
            logoutParams: {
              returnTo: document.location.origin,
            },
          })
        )
      ),
    {
      dispatch: false,
    }
  );

  public getUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserDetailsAction),
      switchMap(() =>
        this.authService.user$.pipe(
          take(1),
          map(user => {
            if (user) {
              return getUserDetailsSucceededAction({ payload: user });
            }

            return getUserDetailsFailedAction();
          })
        )
      )
    )
  );
}
