import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  checkSessionAction,
  getUserDetailsAction,
  performLoginAction,
  performLogoutAction,
} from './auth.actions';
import { userSelector } from './auth.selectors';
import { IAuthFeature } from './auth.state';

@Injectable()
export class AuthFacade {
  public user$ = this.store.select(userSelector);

  constructor(private store: Store<IAuthFeature>) {}

  public checkSession(): void {
    this.store.dispatch(checkSessionAction());
  }

  public login(): void {
    this.store.dispatch(performLoginAction());
  }

  public logout(): void {
    this.store.dispatch(performLogoutAction());
  }

  public getUserDetails(): void {
    this.store.dispatch(getUserDetailsAction());
  }
}
