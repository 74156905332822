import { Injectable } from '@angular/core';

import { VERSION } from '@env/version';

@Injectable()
export class AppService {
  public init(): void {
    console.info(`App version: ${VERSION}`);
  }
}
