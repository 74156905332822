import { HttpParams } from '@angular/common/http';

import { CampaignStatusEnum } from '@libs/scylla/campaigns/models/enums/campaign-status.enum';
import { SearchModeEnum } from '@libs/shared/enums';
import { IRequestOptionsModel } from '@libs/shared/interfaces';

export const getHttpParams = (options: IRequestOptionsModel) => {
  return Object.entries(options).reduce((queryParams, [key, value]) => {
    if (key === 'filters') {
      if (value === undefined || !Object.keys(value).length) {
        return queryParams;
      }

      const filterValues = parseFilters(value);

      return filterValues.reduce((params, filterValue) => {
        let updatedParams = params
          .set(filterValue.field.key, filterValue.field.value)
          .set(filterValue.value.key, filterValue.value.value);

        // Feature 162751 Create Status of Archive for Campaigns
        // to exclude archived entities from the search,
        // we need to add filtering parameters with the correctly selected search mode
        if (filterValue.value.value === CampaignStatusEnum.Archived) {
          updatedParams = updatedParams.set(
            filterValue.searchMode.key,
            filterValue.searchMode.value
          );
        }

        return updatedParams;
      }, queryParams);
    }

    if (value === undefined) {
      return queryParams;
    }

    return queryParams.set(key, value);
  }, new HttpParams());
};

const parseFilters = (filters: any) => {
  return Object.keys(filters).map((key, index) => {
    return {
      field: {
        key: `filters[${index}].Field`,
        value: key,
      },
      value: {
        key: `filters[${index}].Value`,
        value: filters[key],
      },
      searchMode: {
        key: `filters[${index}].SearchMode`,
        value: SearchModeEnum.NotEquals,
      },
    };
  });
};
