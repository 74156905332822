import { Inject, Injectable } from '@angular/core';
import { DimensionsHelper } from '@swimlane/ngx-datatable';

import { WINDOW } from '@libs/shared/providers';

@Injectable()
export class DatatableDimensionsService implements DimensionsHelper {
  constructor(@Inject(WINDOW) private window: Window) {}

  public getDimensions(element: Element): DOMRect {
    return {
      ...element.getBoundingClientRect(),
      width: parseFloat(this.window.getComputedStyle(element)?.width),
    };
  }
}
