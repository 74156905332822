/* eslint-disable */
export const doesUrlMatchGenerically = (
  urlFromRouter: string | undefined,
  genericRoute: string
): boolean => {
  // Handling a bad router state
  if (urlFromRouter === undefined) {
    return false;
  }

  //removing token from url if its there
  if (urlFromRouter.indexOf('#') > -1) {
    urlFromRouter = urlFromRouter.substr(0, urlFromRouter.indexOf('#'));
  }
  //remove ? and ; since they're part of query strings
  if (urlFromRouter.includes('?')) {
    urlFromRouter = urlFromRouter.split('?')[0];
  }
  if (urlFromRouter.includes(';')) {
    urlFromRouter = urlFromRouter.split(';')[0];
  }
  const routerParts = urlFromRouter.split('/');
  const genericParts = genericRoute.split('/');

  if (routerParts.length !== genericParts.length) {
    return false;
  }

  for (let i = 0; i < genericParts.length; i++) {
    if (genericParts[i].charAt(0) !== ':') {
      if (genericParts[i] !== routerParts[i]) {
        return false;
      }
    } else {
      if (!routerParts[i] || !routerParts[i].length) {
        //check if it is a guid
        return false;
      }
    }
  }
  return true;
};
