import { IDatatableColumn, IDatatableSort } from '@libs/shared/interfaces';

import { DEFAULT_CURRENT_PAGE, DatatablePagingHelper } from './datatable-paging.helper';
import { DatatableSortingHelper } from './datatable-sorting.helper';

export abstract class AbstractDatatable<T> {
  public columns: IDatatableColumn<T>[] = [];
  public paging = new DatatablePagingHelper();
  public sorting = new DatatableSortingHelper();

  protected abstract loadData(): void;

  public onPageChange(page: number): void {
    this.paging.currentPage = page;

    this.loadData();
  }

  public onSort({ prop, dir }: IDatatableSort): void {
    this.paging.currentPage = DEFAULT_CURRENT_PAGE;

    this.sorting.add(prop, dir);

    this.loadData();
  }
}
