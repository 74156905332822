import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { LoaderComponent } from './loader.component';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private modalRef: NgbModalRef | null = null;
  private loaderCount: number = 0;

  constructor(protected modalService: NgbModal) {}

  public showLoader() {
    if (this.loaderCount === 0) {
      this.modalRef = this.modalService.open(LoaderComponent, {
        centered: true,
        animation: false,
        backdrop: 'static',
      });
    }

    this.loaderCount++;
  }

  public hideLoader() {
    if (this.loaderCount === 1) {
      this.modalRef?.close();
    }

    this.loaderCount--;
  }
}
