import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  public toasts: any[] = [];

  public show(textOrTpl: string | TemplateRef<any>, type: string = '', options: any = {}) {
    this.toasts.push({ type, textOrTpl, ...options });
  }

  public remove(toast: any) {
    this.toasts = this.toasts.filter((t: any) => t !== toast);
  }
}
