import { HttpParams } from '@angular/common/http';

import { IDictionary, IPatchModel } from '@libs/shared/interfaces';

export const httpParams = (data: IDictionary<any | any[]>): HttpParams => {
  let params = new HttpParams();

  if (data) {
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        data[key].forEach((e: any) => {
          params = params.append(key, e.toString());
        });

        return;
      }

      params = params.append(key, data[key].toString());
    });
  }

  return params;
};

export const httpPatch = (data: any): IPatchModel[] => {
  const result: IPatchModel[] = [];

  if (typeof data === 'object') {
    Object.keys(data).forEach(key => {
      if (data[key] && key !== 'id') {
        result.push({
          op: 'replace',
          path: `/${key}`,
          value: data[key],
        });
      }
    });
  }

  return result;
};
