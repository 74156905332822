import { MemoizedSelector } from '@ngrx/store/src/selector';

import { IListViewState } from './i-list-view-state';

export interface IListViewSelectors<D, Q> {
  getListViewFeatureState: MemoizedSelector<any, IListViewState<D, Q>>;
  getListViewState: MemoizedSelector<any, IListViewState<D, Q>>;
  getListViewRequestParameters: MemoizedSelector<any, Q>;
  getListViewData: MemoizedSelector<any, D[]>;
}
