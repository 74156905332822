import { createReducer, on } from '@ngrx/store';

import { getUserDetailsFailedAction, getUserDetailsSucceededAction } from './auth.actions';
import { IAuthState, authInitialState } from './auth.state';

export const authReducer = createReducer<IAuthState>(
  authInitialState,
  on(getUserDetailsSucceededAction, (state, action) => ({
    ...state,
    user: action.payload,
  })),
  on(getUserDetailsFailedAction, state => ({
    ...state,
    user: authInitialState.user,
  }))
);
