import { createReducer, on } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';

import { IListViewActions, IListViewState } from '../interfaces';

export const createListViewReducer = <T, Q>(
  initialState: IListViewState<T, Q>,
  actions: IListViewActions<T, Q>
): ActionReducer<IListViewState<T, Q>> => {
  const { loadData, loadingDataError, loadingDataSuccess, resetState, resetRequestParameters } =
    actions;

  return createReducer(
    initialState,
    on(loadData, (state, action) => ({
      ...state,
      isLoading: true,
      requestParameters: { ...action.payload },
    })),
    on(loadingDataSuccess, (state, action) => ({
      ...state,
      data: action.payload.data,
      totalCount: action.payload.totalCount,
      error: null,
      isLoading: false,
      isLoaded: true,
    })),
    on(loadingDataError, (state, action) => ({
      ...state,
      isLoading: false,
      isLoaded: true,
      data: [],
      error: action.payload,
    })),
    on(resetState, (state, action) => {
      if (!action.payload) {
        return { ...initialState };
      }
      if (action.payload.resetQueryParameters) {
        return { ...initialState };
      }
      const { requestParameters } = state;

      return {
        ...initialState,
        requestParameters: {
          ...requestParameters,
        },
      };
    }),
    on(resetRequestParameters, (state, action) => ({
      ...state,
      requestParameters: { ...action.payload },
    }))
  );
};
