export const downloadFile = (data: any, name: string) => {
  const file = new File([data], name);
  const url = URL.createObjectURL(file);

  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = name;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
