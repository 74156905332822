export const DEFAULT_TOTAL_COUNT = 0;
export const DEFAULT_CURRENT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;

export class DatatablePagingHelper {
  public totalCount: number;
  public currentPage: number;
  public pageSize: number;

  constructor(
    totalCount = DEFAULT_TOTAL_COUNT,
    currentPage = DEFAULT_CURRENT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE
  ) {
    this.totalCount = totalCount;
    this.currentPage = currentPage;
    this.pageSize = pageSize;
  }
}
