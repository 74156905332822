import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { storeLogger } from '@libs/shared/utils';

import { IEnvironment } from './models';

/* These come from the injectable-app-settings.js file */
declare let GLOBAL_API_URL: string;
declare let GLOBAL_AUTHCONFIG_DOMAIN: string;
declare let GLOBAL_AUTHCONFIG_CLIENTID: string;
declare let GLOBAL_AUTHCONFIG_AUTHORIZATION_PARAMS_CONNECTION: string;
declare let GLOBAL_AUTHCONFIG_AUTHORIZATION_PARAMS_AUDIENCE: string;
declare let WORKFLOW_API_URL: string;

const apiURI = GLOBAL_API_URL;
const apiFlowURL = WORKFLOW_API_URL;

export const environment: IEnvironment = {
  storeConfig: {
    metaReducers: [storeLogger],
  },
  authConfig: {
    domain: GLOBAL_AUTHCONFIG_DOMAIN,
    clientId: GLOBAL_AUTHCONFIG_CLIENTID,
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    authorizationParams: {
      connection: GLOBAL_AUTHCONFIG_AUTHORIZATION_PARAMS_CONNECTION,
      redirect_uri: location.origin,
      audience: GLOBAL_AUTHCONFIG_AUTHORIZATION_PARAMS_AUDIENCE,
    },
    httpInterceptor: {
      allowedList: [`${apiURI}/api/v1/*`, `${apiFlowURL}/api/v1/*`],
    },
  },
  apiUri: apiURI,
  apiFlowURL: apiFlowURL,
  rootModules: [StoreDevtoolsModule.instrument({ maxAge: 25 })],
  pendoApiKey: '61da1b56-bfdb-4b0b-5b8a-1b710801e933',
};
