import { createAction, props } from '@ngrx/store';

import { IApiErrorResponse, IApiResponse } from '@libs/shared/interfaces';

import { IListViewActions } from '../interfaces';

export const createListViewActions = <D, Q>(storageName: string): IListViewActions<D, Q> => ({
  loadData: createAction(`[${storageName}] load data`, props<{ payload: Q }>()),
  loadingDataSuccess: createAction(
    `[${storageName}] loading data success`,
    props<{ payload: IApiResponse<D[]> }>()
  ),
  loadingDataError: createAction(
    `[${storageName}] loading data error`,
    props<{ payload: IApiErrorResponse }>()
  ),
  resetState: createAction(
    `[${storageName}] reset state`,
    props<{ payload?: { resetQueryParameters: boolean } }>()
  ),
  resetRequestParameters: createAction(
    `[${storageName}] reset request parameters`,
    props<{ payload: Q }>()
  ),
});
