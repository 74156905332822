import { User } from '@auth0/auth0-angular';
import { createAction, props } from '@ngrx/store';

import { IPayload } from '@libs/shared/interfaces';

export enum AuthActionTypesEnum {
  CheckSession = '[Auth] Check Session',
  PerformLogin = '[Auth] Perform Login',
  PerformLogout = '[Auth] Perform Logout',

  GetUserDetails = '[Auth] Get User Details',
  GetUserDetailsSucceeded = '[Auth] Get User Details Succeeded',
  GetUserDetailsFailed = '[Auth] Get User Details Failed',
}

export const checkSessionAction = createAction(AuthActionTypesEnum.CheckSession);

export const performLoginAction = createAction(AuthActionTypesEnum.PerformLogin);

export const performLogoutAction = createAction(AuthActionTypesEnum.PerformLogout);

export const getUserDetailsAction = createAction(AuthActionTypesEnum.GetUserDetails);

export const getUserDetailsSucceededAction = createAction(
  AuthActionTypesEnum.GetUserDetailsSucceeded,
  props<IPayload<User>>()
);

export const getUserDetailsFailedAction = createAction(AuthActionTypesEnum.GetUserDetailsFailed);
