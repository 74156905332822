import { ActionCreator } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import { IApiErrorResponse, IApiResponse } from '@libs/shared/interfaces';

export interface IListViewActions<D, Q> {
  loadData: ActionCreator<string, (props: { payload: Q }) => { payload: Q } & TypedAction<string>>;
  loadingDataSuccess: ActionCreator<
    string,
    (props: { payload: IApiResponse<D[]> }) => { payload: IApiResponse<D[]> } & TypedAction<string>
  >;
  loadingDataError: ActionCreator<
    string,
    (props: { payload: IApiErrorResponse }) => { payload: IApiErrorResponse } & TypedAction<string>
  >;

  resetState: ActionCreator<
    string,
    (props: {
      payload?: { resetQueryParameters: boolean };
    }) => { payload?: { resetQueryParameters: boolean } } & TypedAction<string>
  >;
  resetRequestParameters: ActionCreator<
    string,
    (props: { payload: Q }) => { payload: Q } & TypedAction<string>
  >;
}
