import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IListViewSelectors, IListViewState } from '../interfaces';

export const createListViewSelectors = <D, Q>(featureName: string): IListViewSelectors<D, Q> => {
  const getFeature = createFeatureSelector<IListViewState<D, Q>>(featureName);

  const getState = createSelector<any, IListViewState<D, Q>, IListViewState<D, Q>>(
    getFeature,
    store => store
  );

  const getData = createSelector<any, IListViewState<D, Q>, D[]>(getFeature, store => store.data);

  const getRequestParameters = createSelector<any, IListViewState<D, Q>, Q>(
    getFeature,
    state => state.requestParameters
  );

  return {
    getListViewFeatureState: getFeature,
    getListViewState: getState,
    getListViewData: getData,
    getListViewRequestParameters: getRequestParameters,
  };
};
