export enum FormErrorsEnum {
  Required = 'required',
  MinLength = 'minlength',
  MaxLength = 'maxlength',
  Min = 'min',
  Max = 'max',
  Email = 'email',
  Custom = 'customError',
  EmailExist = 'emailExist',
  PasswordStrength = 'passwordStrength',
  MatchError = 'matchError',
  PhoneFormat = 'phoneFormat',
  Pattern = 'pattern',
}
