import { Injectable } from '@angular/core';
import { NgxPendoService } from 'ngx-pendo';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    pendo: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PendoFacadeService {
  constructor(private ngxPendoService: NgxPendoService) {}

  public initializePendo(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    user: { id: string; full_name: string; first_name: string; last_name: string; drbUser: string },
    account: { id: string }
  ) {
    this.ngxPendoService.initialize(user, account);
  }

  public trackEvent(eventName: string, metadata: any) {
    if (window.pendo && window.pendo.isReady && window.pendo.isReady()) {
      window.pendo.track(eventName, metadata);
    }
  }
}
