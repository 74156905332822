import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class NgbDateCustomParserFormatterService extends NgbDateParserFormatter {
  public parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('/');
      if (
        dateParts.length === 3 &&
        !isNaN(+dateParts[0]) &&
        !isNaN(+dateParts[1]) &&
        !isNaN(+dateParts[2])
      ) {
        return { month: +dateParts[0], day: +dateParts[1], year: +dateParts[2] };
      }
    }

    return null;
  }

  public format(date: NgbDateStruct | null): string {
    return date ? `${this.pad(date.month)}/${this.pad(date.day)}/${date.year}` : '';
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }
}
