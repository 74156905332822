import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { IApiResponse } from '@libs/shared/interfaces';

import { environment } from '@env/scylla';

const API_VERSION = 'v1';
const URL_SHORTENER_API_URL = `${environment.apiUri}/api/${API_VERSION}/TemplateMessage/url/shorten`;

@Injectable({
  providedIn: 'root',
})
export class UrlShortenerService {
  constructor(private http: HttpClient) {}

  public shortenUrl(longUrl: string): Observable<string> {
    return this.http
      .post<IApiResponse<string>>(URL_SHORTENER_API_URL, { longUrl })
      .pipe(map(result => result.data));
  }
}
