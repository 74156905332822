import { HttpParameterCodec, HttpParams } from '@angular/common/http';

/* eslint-disable */
export const GetHttpParams = (
  options: GetAllOptions // eslint-disable-line
) =>
  Object.entries(options).reduce(
    (queryParams, [key, value]) => {
      if (key === 'filters') {
        if (value === undefined || !Object.keys(value).length) {
          return queryParams;
        }

        const parsedFilters = parseFilters(value);

        return parsedFilters.reduce((params, parsedFilter) => {
          if (
            parsedFilter.field.value === 'isActive' &&
            parsedFilter.filter.value === 'NotEquals'
          ) {
            return params
              .set(parsedFilter.field.key, parsedFilter.field.value)
              .set(parsedFilter.filter.key, 'false')
              .set(parsedFilter.searchMode.key, parsedFilter.searchMode.value);
          } else {
            return params
              .set(parsedFilter.field.key, parsedFilter.field.value)
              .set(parsedFilter.filter.key, parsedFilter.filter.value);
          }
        }, queryParams);
      }
      if (value === '' || !value) {
        return queryParams;
      }
      return queryParams.set(key, value);
    },
    new HttpParams({ encoder: new HttpEncoder() })
  );

const parseFilters = (filters: any) =>
  Object.keys(filters).map((key, index) => ({
    field: {
      key: `filters[${index}].Field`,
      value: key,
    },
    filter: {
      key: `filters[${index}].Value`,
      value: filters[key],
    },
    searchMode: {
      key: `filters[${index}].SearchMode`,
      value: '3',
    },
  }));

export class HttpEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

export interface GetAllOptions {
  pageSize?: number;
  currentPage?: number;
  fieldName?: string;
  fieldValue?: string;
  sort?: string;
  sortDirection?: string;
  filters?: any;
  searchColumn?: string;
  searchTerm?: string;
  segmentId?: string;
  allSites?: boolean;
  ['QueryParams.ShouldAllMatch']?: string;
}
