import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FormControlStatusesEnum } from '@libs/shared/enums';

@Directive()
export class ElementBase {
  @Input()
  public label: string | null = null;

  @Input()
  public formControlObject: FormControl | any = null;

  @Output()
  public valueChanged = new EventEmitter<any>();

  public get isDisabled(): boolean {
    return this.formControlObject?.disabled || false;
  }

  public get isTouched(): boolean {
    return this._isTouched || this.formControlObject?.touched || false;
  }

  public get isValid(): boolean {
    return (
      this.formControlObject?.valid ||
      this.formControlObject?.status === FormControlStatusesEnum.Pending ||
      this.formControlObject?.status === FormControlStatusesEnum.Disabled
    );
  }

  public isRequired: boolean = false;

  private _isTouched: boolean = false;

  protected markAsTouched() {
    this._isTouched = true;
    this.formControlObject?.markAsTouched();
  }

  protected markAsUntouched() {
    this._isTouched = false;
    this.formControlObject?.markAsUntouched();
  }
}
