import { Directive, Input } from '@angular/core';

import { ElementBase } from './element-base';

@Directive()
export class InputBase extends ElementBase {
  @Input()
  public placeholder: string | null = null;

  @Input()
  public infoMessage: string | null = null;

  @Input()
  public startValue: string | null = null;

  @Input()
  public allowShowErrors: boolean = true;

  @Input()
  public isLoading: boolean = false;
}
