import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from 'angular-svg-icon-preloader';
import { NgxPendoModule } from 'ngx-pendo';

import { AuthStateModule } from '@libs/scylla/auth';
import { HttpTenantIdInterceptor } from '@libs/shared/interceptors/http-tenantid.interceptor';
import { HttpToastsInterceptor } from '@libs/shared/interceptors/http-toasts.interceptor';
import { WINDOW } from '@libs/shared/providers';

import { environment } from '@env/scylla';
import { ENVIRONMENT } from '@env/scylla/providers';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';

export function formatId(value: any) {
  return value.toString().toLowerCase();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AngularSvgIconModule.forRoot(),
    AngularSvgIconPreloaderModule.forRoot({
      configUrl: './assets/svg-icons.json',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerImmediately',
    }),
    StoreModule.forRoot({}, { ...environment.storeConfig }),
    EffectsModule.forRoot([]),
    AuthModule.forRoot({ ...environment.authConfig }),
    AuthStateModule,
    NgxPendoModule.forRoot({
      pendoApiKey: environment.pendoApiKey,
      pendoIdFormatter: formatId,
    }),
    ...environment.rootModules,
  ],
  providers: [
    AppService,
    {
      provide: APP_INITIALIZER,
      useFactory: (app: AppService) => () => app.init(),
      deps: [AppService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpToastsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTenantIdInterceptor,
      multi: true,
    },
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: WINDOW,
      useValue: window,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
