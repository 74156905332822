export const DEFAULT_PREFIX = 'E-';
export const DEFAULT_LENGTH = 16;
export const DIVIDER = 4;

export const generateGuid = (
  length: number = DEFAULT_LENGTH,
  prefix: string = DEFAULT_PREFIX
): string => {
  const bulk = Math.ceil(length / DIVIDER);
  let guid = '';

  for (let i = 0; i < bulk; i++) {
    guid += Math.random().toString(36).slice(-DIVIDER);
  }

  return `${prefix}${guid.slice(-length)}`;
};
