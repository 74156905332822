import { IListViewState } from '@libs/shared/utils';

export const getListViewInitialState = <T, Q>(initialQueryParameters: Q): IListViewState<T, Q> => ({
  data: [],
  isLoading: false,
  isLoaded: false,
  error: null,
  totalCount: 0,
  requestParameters: initialQueryParameters,
});
