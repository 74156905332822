import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import {
  AUTH_FEATURE_KEY,
  AuthEffects,
  AuthFacade,
  authReducer,
  authInitialState as initialState,
} from './+state';

@NgModule({
  imports: [
    StoreModule.forFeature(AUTH_FEATURE_KEY, authReducer, { initialState }),
    EffectsModule.forFeature([AuthEffects]),
  ],
  providers: [AuthFacade],
})
export class AuthStateModule {}
